<template>
  <div class="picture-container">
    <el-upload
      ref="upload"
      action="/api/v1/user/settings/image"
      :show-file-list="false"
      :auto-upload="false"
      :accept="accept"
      :on-change="select"
      :disabled="readonly"
    >
      <div
        class="picture"
        :class="{ picture_loading: isLoading, picture_borderless: borderless }"
        :style="cssVariables"
      >
        <ui-icon
          v-if="!readonly"
          name="rounded-plus"
          size="24px"
          class="picture-container__plus-icon"
        />
        <ui-loading v-if="isLoading" />
        <img
          v-else
          :src="previewImage"
          class="picture-src"
        />
      </div>
      <h6
        v-if="title && !readonly"
        class="description"
        v-html="title"
      ></h6>
      <template name="trigger">
        <button class="invisible picture-container__trigger-btn" ref="open-file-dialog"></button>
      </template>
    </el-upload>

    <ui-modal v-model="showCropper" :closable="false">
      <template #title>
        {{ cropTitle }}
      </template>
      <template #footer>
        <div class="d-flex justify-content-around w-100">
          <ui-button
            outline
            size="sm"
            type="primary"
            native-type="button"
            class="px-4"
            @click="reset"
          >
            Close
          </ui-button>
          <ui-button
            size="sm"
            type="primary"
            native-type="button"
            class="px-4"
            :disabled="!croppedFile"
            @click="upload"
          >
            Save
          </ui-button>
        </div>
      </template>

      <cropper
        v-bind="cropperSettings"
        :src="selectedImage"
        class="cropper"
        @change="change"
      ></cropper>
    </ui-modal>
  </div>
</template>

<script>
import { Upload } from 'element-ui';
import { Cropper } from 'vue-advanced-cropper';
import toBlob from '@/utils/toBlob';
import resetMixin from '@/mixins/reset-mixin';
import { MB } from '@/utils/bytes';
import UiLoading from './Loading.vue';

const allowTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];

export default {

  components: {
    [Upload.name]: Upload,
    Cropper,
    UiLoading,
  },

  mixins: [
    resetMixin(() => ({
      showCropper: false,
      selectedImage: null,
      selectedType: null,
      croppedFile: null,
      croppedImage: null,
    })),
  ],

  props: {
    size: {
      type: Number,
      default: 100,
    },
    maxMBSize: {
      type: Number,
      default: 5,
    },
    readonly: Boolean,
    image: String,
    title: String,
    isLoading: Boolean,
    borderless: Boolean,
    // 1 - default square
    // 0 - free aspect ratio
    aspectRatio: {
      type: Number,
      default: 1,
    },
    cropTitle: {
      type: String,
      default: 'Upload avatar image',
    },
  },

  computed: {
    cropperSettings() {
      return ({
        stencilProps: {
          aspectRatio: this.aspectRatio,
        },
      });
    },
    photoMaxSize() {
      return this.maxMBSize * MB;
    },
    accept() {
      return allowTypes.join(', ');
    },
    previewImage() {
      // eslint-disable-next-line global-require
      return this.croppedImage || this.image || require('@/assets/images/noimage.png');
    },
    cssVariables() {
      return {
        '--image-size': `${this.size}px`,
      };
    },
  },

  methods: {
    openSelectFile() {
      this.$refs['open-file-dialog'].click();
    },
    select(file) {
      if (!allowTypes.includes(file.raw.type)) {
        this.$showServerError({
          message: `Available formats: ${allowTypes.map((v) => v.replace('image/', ''))}`,
        });
      } else if (file.size > this.photoMaxSize) {
        this.$showServerError({
          message: `Max image size: ${this.maxMBSize}MB`,
        });
      } else {
        this.selectedImage = URL.createObjectURL(file.raw);
        this.selectedType = file.raw.type;
        this.openCropper();
      }
    },
    async change({ coordinates, canvas }) {
      try {
        this.croppedFile = await toBlob(canvas, this.selectedType);
        this.croppedImage = URL.createObjectURL(this.croppedFile);
      } catch {
        this.$showServerError({
          message: 'Can\'t crop selected photo!',
        });
      }
    },
    async upload() {
      this.$emit('save', this.croppedFile);
      this.reset();
    },
    openCropper() {
      this.showCropper = true;
    },
  },
};
</script>

<style lang="scss">
.picture-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__plus-icon {
    position: absolute;
    right: 9px;
    bottom: 0;
  }

  &__trigger-btn {
    // Safari iOS layout fix
    position: absolute;
  }

  .picture {
    width: var(--image-size);
    min-width: var(--image-size);
    height: var(--image-size);
    background-color: #E9E9E9;
    border: 4px solid #fff;
    color: #fff;
    border-radius: 50%;
    margin: 5px auto;
    position: relative;

    > input[type="file"] {
      display: none;
    }

    &_borderless {
      border: none;
    }

    &_loading {
      background: #eee;
    }
  }

  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .picture-src {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .description {
    margin-left: 18px;
    align-self: center;
    text-transform: none;
    text-align: left;
  }

  .cropper {
    height: 50vh;
    background: #fff;
  }
}
</style>
