<template>
  <ul class="pagination" :class="`pagination-${this.type}`">

    <!-- To prev pages -->

    <li class="page-item prev-page" :class="{ disabled: disabledPrevPage }">
      <a class="page-link" @click="prevPage">
        <ui-icon
          name="opening-quotes"
          size="16px"
        />
      </a>
    </li>

    <li
      v-if="minPage > 2"
      class="page-item"
    >
      <a class="page-link" @click="changePage(1)">
        1
      </a>
    </li>

    <li
      v-if="minPage > 2"
      class="page-item"
    >
      <a class="page-link" @click="changePage(minPage - 1)">
        <ui-icon
          name="triple-dots"
          width="12px"
          height="4px"
        />
      </a>
    </li>

    <!-- Page items -->

    <li
      v-for="page in range(minPage, maxPage)"
      :key="page"
      :class="{ active: value === page }"
      class="page-item"
    >
      <a class="page-link" @click="changePage(page)">
        {{ page }}
      </a>
    </li>

    <li
      v-if="maxPage + 1 < pageCount$"
      class="page-item"
    >
      <a class="page-link" @click="changePage(maxPage + 1)">
        <ui-icon
          name="triple-dots"
          width="12px"
          height="4px"
        />
      </a>
    </li>

    <li
      v-if="maxPage < pageCount$"
      class="page-item"
    >
      <a class="page-link" @click="changePage(pageCount$)">
        {{ pageCount$ }}
      </a>
    </li>

    <!-- To next pages -->

    <li class="page-item next-page" :class="{ disabled: disabledNextPage }">
      <a class="page-link" @click="nextPage">
        <ui-icon
          name="closing-quotes"
          size="16px"
        />
      </a>
    </li>
  </ul>
</template>

<script>
export default {

  props: {
    type: {
      type: String,
      default: 'default',
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 1,
    },
    showPages: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    pageCount$() {
      if (this.pageCount > 0) {
        return this.pageCount;
      }

      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }

      return 1;
    },
    showPages$() {
      return Math.min(this.pageCount$, this.showPages);
    },
    halfPages() {
      return Math.floor(this.showPages$ / 2);
    },
    expectMaxPage() {
      return this.value + this.halfPages;
    },
    minPage() {
      if (this.value >= this.showPages$) {
        if (this.expectMaxPage > this.pageCount$) {
          return this.pageCount$ - this.showPages$ + 1;
        }

        return this.value - this.halfPages;
      }

      return 1;
    },
    maxPage() {
      if (this.value >= this.showPages$) {
        if (this.expectMaxPage < this.pageCount$) {
          return this.expectMaxPage;
        }

        return this.pageCount$;
      }

      return this.showPages$;
    },
    disabledPrevPage() {
      return this.value === 1;
    },
    disabledNextPage() {
      return this.value === this.pageCount$;
    },
  },

  watch: {
    perPage() {
      this.changePage(1);
    },
  },

  methods: {
    range(min, max) {
      return Array.from({ length: max - min + 1 }, (_, i) => i + min);
    },
    changePage(page) {
      if (page >= 1 && page <= this.pageCount$) {
        this.$emit('input', page);
      }
    },
    prevPage() {
      this.changePage(this.value - 1);
    },
    nextPage() {
      this.changePage(this.value + 1);
    },
    prevGroup() {
      this.changePage(Math.max(1, this.value - this.showPages$));
    },
    nextGroup() {
      this.changePage(Math.min(this.pageCount$, this.value + this.showPages$));
    },
  },
};
</script>
