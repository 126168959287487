var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("activator",null,null,_vm.scope),_c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.opened),expression:"opened"}],staticClass:"modal fade",class:{
        'show d-block': _vm.opened,
        'd-none': !_vm.opened,
        'modal-mini': _vm.type === 'mini',
        'opaque': _vm.opaque,
      },attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.opened},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.closable && _vm.close()}}},[_c('div',{staticClass:"modal-dialog modal-dialog-scrollable",class:[( _obj = {
          'modal-notice': _vm.type === 'notice'
        }, _obj[("modal-" + _vm.size)] = _vm.size, _obj['modal-wide'] =  _vm.wide, _obj ), _vm.modalClasses]},[_c('div',{staticClass:"modal-content"},[(_vm.isLoading)?_c('div',{staticClass:"modal-body my-5 py-5"},[_c('ui-loading')],1):_vm._e(),(!_vm.noHeader && !_vm.isLoading)?_c('div',{staticClass:"modal-header",class:_vm.headerClasses},[_vm._t("close-button",function(){return [(_vm.closable)?_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-hidden":!_vm.opened},on:{"click":_vm.close}},[_c('ui-icon',{attrs:{"name":"close","size":"20px"}})],1):_vm._e()]},null,_vm.scope),_vm._t("header",function(){return [_c('div',{staticClass:"modal-title"},[_vm._t("title",null,null,_vm.scope)],2)]},null,_vm.scope)],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],ref:"modal-body",staticClass:"modal-body",class:[_vm.bodyClasses, { 'modal-body__scrollable': _vm.scrollable }]},[_vm._t("default",null,null,_vm.scope)],2),(!_vm.noFooter)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer",null,null,_vm.scope)],2):_vm._e()])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }