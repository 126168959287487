<template>
  <el-popover
    v-bind="$attrs"
    v-on="$listeners"
    popper-class="ui-popover"
    :disabled="disabled"
  >
    <slot name="body">
      <div v-if="$scopedSlots.title || title" class="ui-popover__header">
        <slot name="header">
          {{ title }}
        </slot>
      </div>
      <div class="ui-popover__body">
        <slot>
          {{ content }}
        </slot>
      </div>
    </slot>

    <template #reference>
      <slot name="reference"></slot>
      <ui-icon
        v-if="icon"
        class="ui-popover__icon"
        :name="icon"
        :class="iconClass"
        :size="iconSize"
        :width="iconWidth"
        :height="iconHeight"
      />
    </template>
  </el-popover>
</template>

<script>
import { Popover } from 'element-ui';

export default {
  components: {
    [Popover.name]: Popover,
  },

  props: {
    title: String,
    content: String,
    icon: String,
    iconClass: String,
    iconSize: String,
    iconWidth: String,
    iconHeight: String,
    disabled: Boolean,
  },
};
</script>
