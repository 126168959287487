<template>
  <ui-input
    v-bind="$attrs"
    v-model="inputValue"
    :label="$t('form.labels.dateOfBirth')"
    v-mask="dateMask"
    @input="onChange"
  />
</template>

<script>
import parseDate from '@/utils/parseDate';
import formatDate from '@/filters/date';
import UiInput from './Input.vue';

export default {

  inheritAttrs: false,

  components: {
    UiInput,
  },

  props: {
    value: [String, Date],
  },

  data: () => ({
    inputValue: '',
    dateMask: {
      mask: '##/##/####',
      masked: true,
      tokens: {
        '#': { pattern: /\d/ },
      },
    },
  }),

  watch: {
    value: {
      immediate: true,
      handler(value) {
        value = parseDate(value, false /* defaultDate */);

        if (value) {
          this.inputValue = formatDate(value, 'dd/LL/yyyy');
        } else {
          this.inputValue = '';
        }
      },
    },
  },

  methods: {
    onChange() {
      if (!this.inputValue) {
        this.$emit('input', '');
      }

      if (!/^(\d{2}\/){2}\d{4}$/.test(this.inputValue)) {
        return;
      }

      const [d, m, y] = this.inputValue.split('/');
      const newValue = parseDate(`${y}-${m}-${d}`, false);
      const oldValue = this.value ? parseDate(this.value) : null;

      if (!oldValue || oldValue.getTime() !== newValue.getTime()) {
        this.$emit('input', newValue);
      }
    },
  },
};
</script>
