<template>
  <div
    class="ui-tabs"
    :class="[`ui-tabs--${type}`]"
  >
    <ul
      v-show="tabs.length > 1"
      class="ui-tabs__items"
      :class="[`ui-tabs__items--${justify}`]"
    >
      <li
        v-for="item in tabs"
        :key="item.id"
        @click="change(item)"
        class="ui-tabs__item"
        :class="{ 'ui-tabs__item_active': item.id === selected }"
      >
        <div class="ui-tabs__item-title">
          {{ item.title }}
        </div>
      </li>
    </ul>

    <p v-if="title" class="ui-tabs__title">
      {{ title }}
    </p>

    <div class="ui-tabs__body">
      <transition name="ui-tabs__fade" mode="out-in">
        <slot :name="selected" />
      </transition>
    </div>
  </div>
</template>

<script>
const TABS_TYPES = {
  DEFAULT: 'default',
  BUBBLE: 'bubble',
};

export default {

  props: {
    type: {
      type: String,
      default: 'default',
    },
    justify: {
      type: String,
      default: 'start',
    },
    tabs: Array,
    title: String,
    value: String,
  },

  data() {
    return {
      selected: this.value,
    };
  },

  computed: {
    isBubbleType() {
      return this.type === TABS_TYPES.BUBBLE;
    },
  },

  watch: {
    value(value) {
      if (value !== this.selected) {
        this.selected = value;
      }
    },
    selected(selected) {
      if (selected !== this.value) {
        this.$emit('input', selected);
      }
    },
  },

  mounted() {
    if (!this.value) {
      this.selected = this.tabs?.[0]?.id;
    }
  },

  methods: {
    change({ id }) {
      this.selected = id;
    },
  },
};
</script>
