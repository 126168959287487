var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-input',_vm._b({attrs:{"label-classes":{ 'select-label' : _vm.required },"value":_vm.value,"is-filled":true,"required":_vm.required,"disabled":_vm.disabled}},'ui-input',_vm.$attrs,false),[_c('el-select',_vm._g({class:[
      ("select-" + _vm.type),
      {
        'select_bordered': _vm.bordered,
        'select_borderless': !_vm.bordered,
        'select_large': _vm.large,
        'select_transparent': _vm.transparent,
      } ],attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"filterable":_vm.isFilterable,"loading":!_vm.hideDropdownLoading && _vm.isLoading,"disabled":_vm.disabled,"remote":_vm.isRemote,"remote-method":_vm.elRemoteMethod,"multiple":_vm.multiple,"collapse-tags":_vm.collapseTags,"value-key":_vm.valueKey,"popper-class":("\n      " + (_vm.hideEmptyDropdown && !_vm.remoteItems.length ? 'hide-dropdown' : '') + "\n      " + (_vm.smallDropdown ? 'small-dropdown' : '') + "\n    ")}},_vm.$listeners),[_vm._t("default",function(){return [(_vm.optionalKey && !_vm.touchScreen)?_vm._l((_vm.elItems),function(item){return _c('ui-popover',{key:item[_vm.valueKey],attrs:{"placement":"left","width":"300","icon-size":"20px","trigger":"hover"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('el-option',{staticClass:"text-truncate",attrs:{"label":item[_vm.labelKey],"value":_vm.valueOriginal ? item : item[_vm.valueKey],"disabled":item.disabled}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item[_vm.optionalKey])+" ")])}):_vm._l((_vm.elItems),function(item){return _c('el-option',{key:item[_vm.valueKey],attrs:{"label":item[_vm.labelKey],"value":_vm.valueOriginal ? item : item[_vm.valueKey],"disabled":item.disabled}})})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }