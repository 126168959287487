<template>
  <label class="ui-switch-wrapper">
    <div
      class="ui-switch"
      :class="{
        [`ui-switch_${type}`]: type,
        [`ui-switch_${size}`]: size,
        'ui-switch_disabled': disabled,
      }"
    >
      <input
        :value="value"
        :checked="value"
        :disabled="disabled"
        type="checkbox"
        @input="$emit('input', !value)"
      />
      <div class="ui-switch__slider round" />
    </div>
    <div v-if="label" class="ui-switch__label">{{ label }}</div>
  </label>
</template>

<script>
export default {
  name: 'Switcher',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    type: String,
    size: String,
    disabled: Boolean,
  },
};
</script>
