<template>
  <div>
    <slot v-bind="{ process, value }"></slot>
  </div>
</template>

<script>
import { random } from 'lodash';

export default {

  props: {
    length: {
      type: Number,
      default: 8,
    },
    debounce: {
      type: Number,
      default: 100,
    },
  },

  data: () => ({
    process: true,
    value: '',
  }),

  watch: {
    value() {
      this.$emit('change', this.value);
    },
  },

  mounted() {
    this.generate();
  },

  methods: {
    generate() {
      this.process = true;

      const handler = setInterval(() => {
        this.value += this.createChar();

        if (this.length <= this.value.length) {
          clearInterval(handler);
          this.process = false;
        }
      }, this.debounce);
    },
    createChar() {
      return random(35).toString(36)[random(0, 1) ? 'toLowerCase' : 'toUpperCase']();
    },
  },
};
</script>
