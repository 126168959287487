<template>
  <ui-input
    v-bind="$attrs"
    :label-classes="{ 'select-label' : required }"
    :value="value"
    :is-filled="true"
    :required="required"
    :disabled="disabled"
  >
    <el-select
      :value="value"
      :placeholder="placeholder"
      :filterable="isFilterable"
      :loading="!hideDropdownLoading && isLoading"
      :disabled="disabled"
      :remote="isRemote"
      :remote-method="elRemoteMethod"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :class="[
        `select-${type}`,
        {
          'select_bordered': bordered,
          'select_borderless': !bordered,
          'select_large': large,
          'select_transparent': transparent,
        },
      ]"
      :value-key="valueKey"
      :popper-class="`
        ${hideEmptyDropdown && !remoteItems.length ? 'hide-dropdown' : ''}
        ${smallDropdown ? 'small-dropdown' : ''}
      `"
      v-on="$listeners"
    >
      <slot>
        <template v-if="optionalKey && !touchScreen">
          <ui-popover
            v-for="item in elItems"
            :key="item[valueKey]"
            placement="left"
            width="300"
            icon-size="20px"
            trigger="hover"
          >
            {{ item[optionalKey] }}
            <template #reference>
              <el-option
                :label="item[labelKey]"
                :value="valueOriginal ? item : item[valueKey]"
                :disabled="item.disabled"
                class="text-truncate"
              />
            </template>
          </ui-popover>
        </template>

        <el-option
          v-else
          v-for="item in elItems"
          :label="item[labelKey]"
          :value="valueOriginal ? item : item[valueKey]"
          :key="item[valueKey]"
          :disabled="item.disabled"
        />
      </slot>
    </el-select>
  </ui-input>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    value: {
      required: true,
    },
    placeholder: String,
    type: {
      type: String,
      default: 'secondary',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    valueOriginal: Boolean,
    labelKey: {
      type: String,
      default: 'name',
    },
    optionalKey: String,
    hideDropdownLoading: Boolean,
    hideEmptyDropdown: Boolean,
    remoteMethod: Function,
    filterable: Boolean,
    bordered: Boolean,
    large: Boolean,
    disabled: Boolean,
    required: Boolean,
    smallDropdown: Boolean,
    multiple: Boolean,
    collapseTags: Boolean,
    transparent: Boolean,
  },

  data: () => ({
    isLoading: false,
    remoteItems: [],
    touchScreen: false,
  }),

  computed: {
    isRemote() {
      return typeof this.remoteMethod === 'function';
    },
    isFilterable() {
      return this.isRemote || this.filterable;
    },
    elItems() {
      if (this.isRemote && this.remoteItems.length) {
        return this.remoteItems;
      }

      return this.items;
    },
  },

  methods: {
    async elRemoteMethod(query) {
      this.isLoading = true;

      try {
        this.remoteItems = await this.remoteMethod(query);
      } finally {
        this.isLoading = false;
      }
    },
    touchStartListener() {
      this.touchScreen = true;
      window.removeEventListener('touchstart', this.touchStartListener);
    },
  },

  mounted() {
    window.addEventListener('touchstart', this.touchStartListener);
  },

  beforeDestroy() {
    window.removeEventListener('touchstart', this.touchStartListener);
  },
};
</script>
