<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'has-danger': error,
      'input-group-focus': focused,
      'has-label': label || $slots.label,
      'has-placeholder': placeholder,
      'has-success': hasSuccess,
      'is-focused': focused,
      'is-filled': isFilled$,
      'is-disabled': disabled,
      'form-group_search': search,
      'form-group_bordered': bordered,
      'form-group_input-icon': inputIcon,
    }"
    >
    <slot name="label">
      <label v-if="label" class="form-group__label" :class="labelClasses">
        {{label}}
        <span class="text-danger" v-if="required">*</span>
        <el-tooltip v-if="labelInfo" placement="top">
          <div slot="content">
            <div v-html="labelInfo" />
          </div>
          <i class="nc-icon nc-alert-circle-i label-info"></i>
        </el-tooltip>
      </label>
    </slot>

    <div
      class="form-group__body"
      :class="{
        'form-group__body_label-overlay': labelOverlay,
      }"
    >
      <div
        v-if="addonLeftIcon || $slots.addonLeft"
        class="input-group-prepend"
        :disabled="disabled"
      >
          <span
            class="input-group-text"
            @click="$emit('click-left-icon')"
          >
            <slot name="addonLeft">
              <i :class="addonLeftIcon"></i>
            </slot>
          </span>
      </div>
      <div v-if="isLoading" class="position-relative w-100">
        <input class="form-control" />
        <ui-loading />
      </div>
      <template v-else>
        <ui-icon
          v-if="search"
          name="search"
          size="16"
          class="form-group__search-icon"
        />
        <ui-icon
          v-if="inputIcon"
          :name="inputIcon"
          size="20"
          class="form-group__custom-icon"
        />
        <slot>
          <component
            v-if="tag === 'input' || tag === 'textarea'"
            :is="tag"
            key="input-value-native"
            ref="input"
            :placeholder="placeholder"
            :value.prop="value"
            :disabled="disabled"
            v-on="listeners"
            v-bind="controlScope"
            @keypress="numberHandler"
            @paste="numberHandler"
          />
          <component
            v-else
            :is="tag"
            key="input-value-prop"
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            v-on="listeners"
            ref="input"
            v-bind="controlScope"
          ></component>
          <span
            v-if="extPostfix"
            class="d-flex text-grey font-12 align-self-end ml-2"
          >
            <slot name="extPostfix"></slot>
          </span>
        </slot>
        <div
          v-if="addonRightIcon || $slots.addonRight"
          class="input-group-append"
          :disabled="disabled"
        >
          <span
            class="input-group-text"
            @click="$emit('click-right-icon')"
          >
            <slot name="addonRight">
              <i :class="addonRightIcon"></i>
            </slot>
          </span>
        </div>
        <ui-text-copy
          v-if="copyButton"
          :text="value"
          :size="20"
          class="form-group__copy"
        />
      </template>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        v-if="showErrorMessage"
        class="invalid-feedback error-text"
        :class="{'mt-2': hasIcon}"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>

<script>
import { Tooltip } from 'element-ui';
import UiIcon from '../Icon.vue';
import UiLoading from '../Loading.vue';

export default {

  inheritAttrs: false,

  components: {
    [Tooltip.name]: Tooltip,
    UiIcon,
    UiLoading,
  },

  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: [String, Boolean],
      description: 'Input error (below input)',
    },
    labelClasses: {
      type: [String, Array, Object],
      description: 'Input label css classes',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      description: 'Input value',
    },
    addonRightIcon: {
      type: [String, Array],
      description: 'Addon right icon',
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon',
    },
    tag: {
      type: String,
      default: 'input',
    },
    labelInfo: {
      type: String,
      default: '',
    },
    isInteger: {
      type: Boolean,
      description: 'true - only numbers can be entered',
    },
    isFloat: {
      type: Boolean,
      description: 'true - can enter numbers, periods and commas',
    },
    labelOverlay: Boolean,
    extPostfix: Boolean,
    placeholder: String,
    inputIcon: String,
    search: Boolean,
    bordered: Boolean,
    isLoading: Boolean,
    disabled: Boolean,
    capitalized: Boolean,
    uppercase: Boolean,
    copyButton: Boolean,
    isFilled: undefined,
    mask: undefined,
  },

  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
    };
  },

  computed: {
    isNumber() {
      return this.isFloat || this.isInteger;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasSuccess() {
      return this.hadError && this.touched && !this.error;
    },
    showErrorMessage() {
      return this.error && typeof this.error === 'string';
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    },
    isFilled$() {
      if (typeof this.isFilled === 'boolean') {
        return this.isFilled;
      }

      if (
        this.value === null
        || this.value === undefined
        || this.value === ''
      ) {
        return false;
      }

      return `${this.value}`.length > 0;
    },
    controlScope() {
      const attributes = {
        ...this.$attrs,
        class: [
          'form-control',
          {
            'is-valid': this.hasSuccess,
            'is-invalid': this.error,
          },
          this.inputClasses,
        ],
        required: this.required,
        'aria-describedby': 'addon-right addon-left',
      };
      if (this.isNumber) {
        attributes.type = 'number';
      }
      return attributes;
    },
  },

  methods: {
    numberHandler(evt) {
      if (!this.isNumber) return true;
      const value = evt.type === 'paste' ? evt.clipboardData.getData('Text') : evt.key;
      if (this.isInteger && !(/^\d+$/.test(value))) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    updateValue(evt) {
      let value = evt instanceof Event ? evt.target.value : evt;
      if (!this.touched && value) {
        this.touched = true;
      }

      if (this.capitalized) {
        value = this.capitalizeByChar(value, '-');
        value = this.capitalizeByChar(value, ' ');
      }

      if (this.uppercase) {
        value = value.toUpperCase();
      }

      this.$emit('input', value);

      if (this.tag === 'textarea') {
        this.autoHeight(evt.target);
      }
    },
    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    },
    autoHeight(el) {
      if (el) {
        el.style.height = 'auto';
        el.style.height = `${el.scrollHeight}px`;
      }
    },
    capitalizeByChar(data, char) {
      return data
        .split(char)
        .map((s) => {
          return `${s.charAt(0).toUpperCase()}${s.substring(1)}`;
        })
        .join(char);
    },
  },

  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true;
      }
    }, { immediate: true });
  },

  mounted() {
    if (this.tag === 'textarea') {
      this.$nextTick(() => {
        this.autoHeight(this.$refs.input);
      });
    }
  },
};
</script>

<style>
.error-text {
  display: block;
}

.label-info {
  position: relative;
  bottom: -2px;
  margin-left: 3px;
}
</style>
