<template>
  <el-date-picker
    v-bind="$attrs"
    :picker-options="insidePickerOptions"
    v-on="$listeners"
    class="form-control"
  />
</template>

<script>
import { DatePicker } from 'element-ui';

export default {

  inheritAttrs: false,

  components: {
    [DatePicker.name]: DatePicker,
  },

  props: {
    pickerOptions: Object,
    min: Date,
    max: Date,
  },

  computed: {
    insidePickerOptions() {
      return {
        disabledDate: (time) => {
          if (this.min && time < this.min) {
            return true;
          }

          if (this.max && time > this.max) {
            return true;
          }

          return false;
        },
        ...this.pickerOptions,
      };
    },
  },
};
</script>
