<template>
  <component
    :is="tag"
    :type="nativeType"
    class="btn position-relative"
    :class="{
      [`btn-outline-${type}`]: outline,
      [`btn-${size}`]: size,
      [`btn-${type}`]: type,
      'btn-icon': icon,
      'btn-round': round,
      'btn-block': block,
      'btn-social': social,
      'btn-link': link,
      'btn-wd': wide,
      'btn-action': action,
    }"
    v-on="$listeners"
>
    <span
      class="btn-content"
      :class="{ 'invisible': isLoading }"
    >
      <span v-if="$slots.label" class="btn-label">
        <slot name="label" />
      </span>
      <slot />
      <span v-if="$slots.labelRight" class="btn-label btn-label-right">
        <slot name="labelRight" />
      </span>
    </span>
    <ui-loading v-if="isLoading" />
  </component>
</template>

<script>
import Loading from './Loading.vue';

export default {

  components: {
    'ui-loading': Loading,
  },

  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Html tag to use',
    },
    type: {
      type: String,
      default: 'default',
      description: 'Color type',
    },
    nativeType: {
      type: String,
      default: 'submit',
      description: 'Native button type (e.g submit|button) ',
    },
    round: Boolean,
    outline: Boolean,
    block: Boolean,
    social: Boolean,
    link: Boolean,
    icon: Boolean,
    wide: Boolean,
    size: String,
    isLoading: Boolean,
    action: Boolean,
  },
};
</script>
