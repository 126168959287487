<template>
  <span class="d-flex">
    <ui-icon
      v-for="index in value"
      :key="index"
      v-bind="$attrs"
      name="star"
      :class="disabled ? 'text-disabled' : 'text-yellow'"
    />
  </span>
</template>

<script>
export default {

  props: {
    value: {
      type: Number,
      default: 0,
    },
    disabled: Boolean,
  },
};
</script>
