<template>
  <component
    :is="baseComponent"
    :to="link.to"
    :class="{active: isActive}"
    tag="li"
    exact
  >
    <a v-if="isMenu"
       data-toggle="collapse"
       href="#"
       @click.prevent="collapseMenu">
      <i :class="link.icon"></i>
      <p>{{link.name}}
        <b class="caret" :class="{rotated: !collapsed}"></b>
      </p>
    </a>
    <collapse-transition v-if="$slots.default || this.isMenu">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>
    <slot name="title" v-if="children.length === 0 && !$slots.default && link.to">
      <router-link
        :to="link.to"
        :class="{active: link.active}"
        @click.native.stop="$emit('click')"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{link.name.substring(0, 1)}}</span>
          <span class="sidebar-normal">{{link.name}}</span>
        </template>
        <template v-else>
          <ui-icon
            v-if="link.icon"
            :name="link.icon"
          />
          <p>{{link.name}}</p>
          <ui-badge
            v-if="link.badge"
            :type="link.badge.type"
            class="ml-auto"
          >
            {{ link.badge.value }}
          </ui-badge>
        </template>
      </router-link>
    </slot>
  </component>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import UiIcon from './Icon.vue';

export default {

  components: {
    CollapseTransition,
    UiIcon,
  },

  props: {
    menu: {
      type: Boolean,
      default: false,
      description: 'Whether item is a menu containing multiple items',
    },
    link: {
      type: Object,
      default: () => ({
        name: '',
        path: '',
        children: [],
      }),
      description: 'Link object',
    },
  },

  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },

  inject: {
    addLink: { default: null },
    removeLink: { default: null },
  },

  data() {
    return {
      children: [],
      collapsed: true,
    };
  },

  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route) {
        const matchingRoute = this.children
          .find((c) => this.$route.path.startsWith(c.link.path));
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
  },

  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },

  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>

<style scoped>
  .caret.rotated {
    transform: rotate(180deg);
  }
</style>
