var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({staticClass:"button",class:[
    ("button_" + _vm.type),
    ("button_" + _vm.size),
    {
      'button_disabled': _vm.disabled,
      'button_wide': _vm.wide,
    }
  ],attrs:{"type":_vm.nativeType}},_vm.$listeners),[(_vm.isLoading)?_c('ui-loading'):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }