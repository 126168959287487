<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="sidebar__header">
      <div class="logo">
        <router-link
          class="simple-text logo-mini"
          aria-label="sidebar mini logo"
          to="/"
          @click.native.stop="$emit('click')"
        >
          <div class="logo-img">
            <slot name="logo">
              <img src="@/assets/images/logo-horizontal-transparent.svg" alt="">
            </slot>
          </div>
        </router-link>
      </div>

      <slot name="header-additional"></slot>
    </div>

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <ui-sidebar-item
            v-for="(link, index) in links"
            :key="link.name + index"
            :link="link"
            @click="$emit('click')"
          >
            <ui-sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
              @click="$emit('click')"
            ></ui-sidebar-item>
          </ui-sidebar-item>
        </slot>
      </ul>
      <ul class="socials">
        <li
          v-for="link in socials"
          :key="link.name"
          class="socials__item"
        >
          <a
            :href="link.url"
            class="socials__link"
            :class="link.name"
            :alt="link.alt"
            target="_blank"
          ></a>
        </li>
      </ul>
      <span v-if="!isWeb" class="app-version">
        App version: {{ version }}
      </span>
    </div>
  </div>
</template>
<script>
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PerfectScrollbar from 'perfect-scrollbar';
import { APP_PLATFORM, APP_VERSION } from '@/config/app';
import UiSidebarItem from './SidebarItem.vue';

export default {

  components: {
    UiSidebarItem,
  },

  data() {
    return {
      socials: [
        {
          name: 'instagram',
          alt: 'instagram icon',
          url: 'https://www.instagram.com/easytipuk',
        },
        {
          name: 'facebook',
          alt: 'facebook icon',
          url: 'https://www.facebook.com/EasyTipofficial',
        },
        {
          name: 'twitter',
          alt: 'twitter icon',
          url: 'https://twitter.com/EasyTipUK',
        },
        {
          name: 'linkedin',
          alt: 'linkedin icon',
          url: 'https://www.linkedin.com/company/easytip',
        },
      ],
      version: APP_VERSION,
      platform: APP_PLATFORM,
    };
  },

  computed: {
    isWeb() {
      return this.platform === 'Web';
    },
  },

  props: {
    backgroundColor: {
      type: String,
      default: 'brown',
      validator: (value) => {
        const acceptedValues = ['white', 'brown', 'black'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color (white|brown|black)',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar active text color (primary|info|success|warning|danger)',
    },
    links: {
      type: Array,
      default: () => [],
      description: 'Sidebar links. Can be also provided as children components (sidebar-item)',
    },
  },

  methods: {
    async initScrollBarAsync() {
      const isWindows = navigator.platform.startsWith('Win');
      if (!isWindows) {
        return;
      }
      // eslint-disable-next-line no-new
      new PerfectScrollbar(this.$refs.sidebarScrollArea);
    },
  },

  mounted() {
    this.initScrollBarAsync();
  },
};
</script>

<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
</style>
