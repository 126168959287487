<template>
  <div
    class="data-table"
    :class="{
      'data-table_separatable': separatable,
      'data-table_empty': !data.length,
      'data-table_empty-header': emptyHeader,
    }"
  >
    <div v-if="$scopedSlots.before" class="data-table__before">
      <slot name="before" />
    </div>

    <div v-if="emptyHeader || data.length" class="data-table__header">
      <div
        v-for="col in columns$"
        :key="`${col.name}_th`"
        class="data-table__label"
        :data-sorting="getSorting(col)"
        :data-direction="getDirection(col)"
        :data-property="col.name"
        :data-empty="!col.label"
        :data-align="col.align"
      >
        <slot :name="`${col.name}-label`" v-bind="{ col }">
          {{ col.label }}

          <span
            v-if="col.sorting"
            class="data-table__sort"
            @click="setSort(col)"
          >
            <i class="data-table__sort-asc"></i>
            <i class="data-table__sort-desc"></i>
          </span>
        </slot>
      </div>
    </div>
    <template v-if="data.length">
      <div
        v-for="(row, index) in data"
        :key="index"
        class="data-table__row"
        :class="getRowClass({ row })"
      >
        <template v-for="col in columns$">
          <div
            :key="`${col.name}_${index}_td`"
            class="data-table__column"
            :class="getColumnClass(col, row)"
            :data-property="col.name"
            :data-align="col.align"
          >
            <template v-if="!col.isVisible || col.isVisible({ row, col })">
              <div
                class="data-table__label"
                :data-empty="!col.label"
              >
                <slot :name="`${col.name}-label`" v-bind="{ col }">
                  {{ col.label }}
                </slot>
              </div>
              <div class="data-table__cell">
                <slot :name="col.name" v-bind="{ index, value: getValue(col, row), row, col }">
                  <template v-if="getValue(col, row)">
                    <slot :name="`${col.name}-value`" v-bind="{ index, value: getValue(col, row), row, col }">
                      {{ getValue(col, row) }}
                    </slot>
                  </template>
                  <slot v-else-if="col.empty" :name="`${col.name}-empty`">
                    <span class="text-gray">
                      {{ col.empty }}
                    </span>
                  </slot>
                </slot>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div v-if="hasTotal" class="data-table__row data-table__total">
        <div
          v-for="col in columns$"
          :key="`${col.name}_tl`"
          class="data-table__column"
          :data-property="col.name"
          :data-empty="!col.total"
          :data-align="col.align"
        >
          <template v-if="col.total">
            <div
              class="data-table__label"
              :data-empty="!col.label"
            >
              <slot :name="`${col.name}-label`" v-bind="{ col }">
                {{ col.label }}
              </slot>
            </div>
            <div class="data-table__cell">
              <div class="data-table__total-label">
                {{ $t('table.total') }}:
              </div>
              <div class="data-table__total-value">
                <slot :name="`${col.name}-total`" v-bind="{ col }" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div v-if="!data.length && !noEmptyText" class="text-gray data-table__empty-text">
      {{ $t('noData') }}
    </div>

    <div v-if="$scopedSlots.after" class="data-table__after">
      <slot name="after" />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    noEmptyText: Boolean,
    rowClass: null,
    columnClass: null,
    separatable: Boolean,
    emptyHeader: Boolean,
  },

  data: () => ({
    sortBy: null,
    sortDirection: null,
  }),

  computed: {
    columns$() {
      return this.columns.filter(({ visible }) => visible !== false);
    },
    hasTotal() {
      return this.columns$.some((col) => col.total);
    },
  },

  methods: {
    setSort({ prop }) {
      if (this.sortBy === prop) {
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.sortBy = prop;
        this.sortDirection = 'ASC';
      }

      this.$emit('sort', {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      });
    },
    getSorting({ sorting }) {
      return sorting;
    },
    getDirection({ prop, sorting }) {
      return sorting && prop === this.sortBy
        ? this.sortDirection
        : undefined;
    },
    getRowClass(scope) {
      if (!this.rowClass) {
        return '';
      }

      if (typeof this.rowClass === 'function') {
        return this.rowClass(scope);
      }

      return this.rowClass;
    },
    getColumnClass(colScope, rowScope) {
      if (!this.columnClass) {
        return '';
      }

      if (typeof this.columnClass === 'function') {
        return this.columnClass(colScope, rowScope);
      }

      return this.columnClass;
    },
    getValue(col, row) {
      const { prop, value } = col;

      if (typeof value === 'function') {
        return value({ row, col });
      }

      if (!prop) {
        return null;
      }

      return row[prop];
    },
  },
};
</script>
