<template>
  <ui-dropdown
    :direction="`${ noDirection ? 'down' : 'bottom-end' }`"
    append-to-body
    @change="onChange"
    class="ui-calendar-picker"
    :disabled="disabled"
  >
    <template #control>
      <ui-input
        :class="{ 'disabled': disabled }"
        :value="title"
        :input-classes="inputClasses$"
        :label="label"
        :placeholder="placeholder"
        :error="error"
        :required="required"
        :input-icon="inputIcon"
        :disabled="disabled"
        :bordered="bordered"
        readonly
      />
    </template>

    <ui-calendar
      v-model="date"
      v-bind="$attrs"
      v-on="$listeners"
      :mode.sync="mode"
      :info="calendarInfo"
      :disabled-date="disabledCalendar"
    />
  </ui-dropdown>
</template>

<script>
import { DateTime } from 'luxon';
import UiDropdown from './Dropdown.vue';
import UiCalendar from './Calendar.vue';

export default {

  components: {
    UiDropdown,
    UiCalendar,
  },

  props: {
    format: {
      type: String,
      default: 'dd.LL.yyyy',
    },
    value: Date,
    label: String,
    placeholder: String,
    error: String,
    inputClasses: String,
    calendarInfo: String,
    inputIcon: String,
    noDirection: Boolean,
    required: Boolean,
    disabled: Boolean,
    bordered: Boolean,
    disabledDate: {
      type: Function,
      default: () => false,
    },
  },

  data: () => ({
    date: new Date(),
    mode: 'day',
  }),

  computed: {
    title() {
      return this.date ? this.datetime.toFormat(this.format) : '';
    },
    datetime() {
      return this.date ? DateTime.fromJSDate(this.date) : null;
    },
    inputClasses$() {
      return [
        this.inputClasses,
        'cursor-pointer',
      ].filter((v) => v).join(' ');
    },
    disabledCalendar() {
      if (this.disabled) {
        return () => true;
      }

      if (this.disabledDate) {
        return this.disabledDate;
      }

      return undefined;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.date = new Date(value);
        } else {
          this.date = null;
        }
      },
    },
    date: {
      handler(date) {
        if (this.value) {
          const current = DateTime.fromJSDate(this.value);
          const newest = DateTime.fromJSDate(date);

          if (current.hasSame(newest, 'day')) {
            return;
          }
        }

        this.$emit('input', date);
      },
    },
  },

  methods: {
    onChange() {
      this.mode = 'day';
    },
  },
};
</script>
