<template>
  <button
    class="button"
    :type="nativeType"
    :class="[
      `button_${type}`,
      `button_${size}`,
      {
        'button_disabled': disabled,
        'button_wide': wide,
      }
    ]"
    v-on="$listeners"
  >
    <ui-loading v-if="isLoading" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'ghost'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'lg',
      validator(value) {
        return ['lg', 'sm'].includes(value);
      },
    },
    nativeType: {
      type: String,
      default: 'submit',
      description: 'Native button type (e.g submit|button) ',
    },
    wide: Boolean,
    isLoading: Boolean,
    disabled: Boolean,
  },
};
</script>
