<template>
  <div class="ui-calendar__range row">
    <div class="col-12 col-xl-6">
      <ui-calendar
        :min="min"
        :max="max"
        :month.sync="leftMonth"
        :year.sync="leftYear"
        :disabled-date="disabledDate"
        :classes-date="classesDate"
        class="ui-calendar__left"
        @input="pickDate"
      />
    </div>
    <div class="col-12 col-xl-6 pl-xl-2">
      <ui-calendar
        :min="min"
        :max="max"
        :month.sync="rightMonth"
        :year.sync="rightYear"
        :disabled-date="disabledDate"
        :classes-date="classesDate"
        class="ui-calendar__right"
        @input="pickDate"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import UiCalendar from './Calendar.vue';

/**
 * Convert value to DateTime.
 *
 * @param {any} value
 * @returns {DateTime}
 */
function convert(value) {
  if (value instanceof DateTime) {
    return value;
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value);
  }

  return null;
}

/**
 * Current local date.
 *
 * @var {DateTime}
 */
const local = DateTime.local();

export default {

  components: {
    UiCalendar,
  },

  props: {
    value: Array,
    min: Date,
    max: Date,
    disabledDate: Function,
    singleDay: Boolean,
  },

  data() {
    return {
      from: this.value?.[0] || null,
      to: this.value?.[1] || null,
      leftMonth: local.month,
      leftYear: local.year,
    };
  },

  computed: {
    rightMonth: {
      get() {
        return this.leftMonth === 12 ? 1 : this.leftMonth + 1;
      },
      set(month) {
        this.changeRight({ month });
      },
    },
    rightYear: {
      get() {
        return this.leftMonth === 12 ? this.leftYear + 1 : this.leftYear;
      },
      set(year) {
        this.changeRight({ year });
      },
    },
  },

  watch: {
    value(value) {
      if (Array.isArray(value)) {
        const [from, to] = value;

        if (from !== this.from) {
          this.from = from;
        }

        if (to !== this.to) {
          this.to = to;
        }
      }
    },
    from: {
      immediate: true,
      handler(from) {
        if (from) {
          const datetime = convert(from);

          this.leftMonth = datetime.month;
          this.leftYear = datetime.year;
        }
      },
    },
  },

  methods: {
    changeRight({ month, year }) {
      if (year) {
        this.leftYear = year;
      }

      if (month === 1) {
        this.leftMonth = 12;
      } else if (month === 2) {
        this.leftMonth = month - 1;
        this.rightYear = this.leftYear + 1;
      } else if (month) {
        this.leftMonth = month - 1;
      }
    },
    pickDate(date) {
      const datetime = convert(date).startOf('day');
      const range = [this.from, this.to];

      date = datetime.toJSDate();

      if (this.isFrom(datetime)) {
        if (this.singleDay) {
          range[1] = date;
        } else {
          range[0] = null;
          if (this.to) {
            range[0] = this.to;
            range[1] = null;
          }
        }
      } else if (this.isTo(datetime)) {
        if (this.singleDay) {
          range[0] = date;
        } else {
          range[1] = null;
        }
      } else if (this.isBetween(date)) {
        range[1] = date;
      } else if (!this.from) {
        range[0] = date;
      } else if (!this.to) {
        range[1] = date;
      } else if (this.isAfter(date)) {
        range[1] = date;
      } else if (this.isBefore(date)) {
        range[0] = date;
      }

      [this.from, this.to] = range;

      this.$emit('input', range);
    },
    isBefore(date) {
      return this.from && this.from > date;
    },
    isAfter(date) {
      return this.to && this.to < date;
    },
    isFrom(datetime) {
      return convert(this.from)?.hasSame(datetime, 'day');
    },
    isTo(datetime) {
      return convert(this.to)?.hasSame(datetime, 'day');
    },
    isBetween(date) {
      return this.from && this.to
        && this.from < date
        && this.to > date;
    },
    classesDate({ date, datetime }) {
      return {
        'ui-calendar__date-range_bound': this.isFrom(datetime) || this.isTo(datetime),
        'ui-calendar__date-range_between': this.isBetween(date),
      };
    },
  },
};
</script>
