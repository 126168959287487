<template>
  <div
    class="ui-icon-upload"
  >
    <ui-icon-button
      class="ui-icon-upload__icon text-primary"
      name="file-upload"
      width="20px"
      height="20px"
      :disabled="disabled"
      @click="handlePickFile"
    />
    <input
      ref="input-upload"
      class="ui-icon-upload__input"
      type="file"
      multiple
      :accept="acceptFiles"
      :disabled="disabled"
      @change="handleFilePicked"
    />
  </div>
</template>

<script>
import UiIconButton from './IconButton.vue';

export default {
  name: 'IconUpload',

  components: {
    UiIconButton,
  },

  props: {
    acceptFiles: String,
    disabled: Boolean,
  },

  methods: {
    handlePickFile() {
      this.$refs['input-upload'].value = '';
      this.$refs['input-upload'].click();
    },
    handleFilePicked(event) {
      this.$emit('upload', event);
    },
  },
};
</script>
