<template>
  <component
    class="dropdown dropdown-absolute"
    :is="tag"
    :class="{
      show: isOpen,
      'dropdown-fixed': appendToBody,
    }"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <slot name="control" :is-open="isOpen">
      <a class="dropdown-toggle"
         :class="{'no-caret': hideArrow}"
         data-toggle="dropdown">
        <i :class="icon"></i>
        <span class="no-icon">
          <slot name="title">
            {{ title }}
          </slot>
        </span>
      </a>
    </slot>
    <ul
      ref="menu"
      class="dropdown-menu"
      :x-placement="directionAttribute"
      :class="{
        'dropdown-menu-right': position === 'right',
        show: isVisible,
      }"
      :style="offset"
    >
      <slot></slot>
    </ul>
  </component>
</template>

<script>
export default {

  props: {
    direction: {
      type: String,
      default: 'down',
      description: 'Drop down menu direction (up|down)',
    },
    title: String,
    icon: String,
    position: {
      type: String,
      description: 'Drop down menu arrow position (left|right)',
    },
    hideArrow: {
      type: Boolean,
      description: 'Whether to hide drop down arrow',
    },
    tag: {
      type: String,
      default: 'li',
      description: 'Html tag of the dropdnown',
    },
    appendToBody: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      isOpen: false,
      isVisible: false,
      offset: {
        top: 0,
        left: 0,
      },
    };
  },

  computed: {
    directionAttribute() {
      if (this.direction.indexOf('-')) {
        return this.direction;
      }

      if (this.direction === 'up') {
        return 'top-start';
      }

      if (this.direction === 'down') {
        return 'bottom-start';
      }

      return '';
    },
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }

      if (!this.appendToBody) {
        return;
      }

      if (isOpen) {
        this.startScrollWatching();
      } else {
        this.endScrollWatching();
      }
    },
  },

  beforeDestroy() {
    this.endScrollWatching();
  },

  methods: {
    toggleDropDown() {
      if (this.disabled) {
        return;
      }

      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);

      this.$nextTick(() => {
        const dropdown = this.$el.getBoundingClientRect();
        const menu = this.$refs.menu.getBoundingClientRect();
        if (this.appendToBody) {
          this.$el.classList.remove('dropdown-absolute');
          const screenHeight = document.body.offsetHeight;
          const bottomOfDropdownMenu = dropdown.bottom + menu.height;
          if (bottomOfDropdownMenu > screenHeight) {
            this.offset.left = `${dropdown.left - (menu.width - dropdown.width) - 35}px`;
            this.offset.top = 'auto';
            this.offset.bottom = '15px';
          } else {
            this.offset.top = `${dropdown.top + dropdown.height + 5}px`;
            this.offset.left = `${dropdown.left - (menu.width - dropdown.width) - 5}px`;
            this.offset.bottom = 'auto';
          }
        } else {
          this.offset.top = `${15}px`;
          this.offset.left = dropdown.left > 50 ? `${-menu.width}px` : 0;
        }

        this.isVisible = true;
      });
    },
    closeDropDown() {
      if (!this.isOpen) {
        return;
      }

      this.isOpen = false;
      this.isVisible = false;
      this.$emit('change', this.isOpen);
    },
    startScrollWatching() {
      window.addEventListener('scroll', this.closeDropDown);
      window.addEventListener('mousewheel', this.closeDropDown);
      window.addEventListener('DOMMouseScroll', this.closeDropDown);
    },
    endScrollWatching() {
      window.removeEventListener('scroll', this.closeDropDown);
      window.removeEventListener('mousewheel', this.closeDropDown);
      window.removeEventListener('DOMMouseScroll', this.closeDropDown);
    },
  },
};
</script>

<style>
  .dropdown {
    list-style-type: none;
  }

  .dropdown .dropdown-toggle {
    cursor: pointer;
  }
</style>
