<template>
  <div v-observe-visibility="handleVisibility" class="ui-otp-input">
    <div class="ui-otp-input__group">
      <otp-input-item
        ref="fields"
        class="ui-otp-input__item"
        :class="{
          'ui-otp-input__item_danger': isDanger,
        }"
        v-for="(item, index) in inputCount"
        :key="index"
        :focus="isActiveInput(index)"
        :value="otp[index]"
        :input-type="inputType"
        :input-classes="inputClasses"
        @input="handleOnInput"
        @keydown="handleOnKeyDown"
        @paste="handleOnPaste"
        @focus="handleOnFocus(index)"
        @blur="handleOnBlur"
      />
    </div>
    <div
      v-if="error"
      class="ui-otp-input__error invalid-feedback error-text"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import OtpInputItem from './OtpInputItem.vue';

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: 'OtpInput',

  components: {
    OtpInputItem,
  },

  props: {
    inputCount: {
      type: Number,
      default: 6,
    },
    inputType: {
      type: String,
      default: 'number',
    },
    error: String,
    isDanger: String,
    inputClasses: String,
    value: String,
  },

  data() {
    return {
      activeInput: 0,
      otp: [],
    };
  },

  methods: {
    handleVisibility(status) {
      if (status) {
        this.$nextTick(() => {
          this.$refs.fields[0].$el.focus();
        });
      }
    },
    isActiveInput(index) {
      return this.activeInput === index;
    },
    handleOnFocus(index) {
      this.activeInput = index;
    },
    handleOnBlur() {
      this.activeInput = -1;
    },
    checkFilledAllInputs() {
      return this.otp.join('').length === this.inputCount
        ? this.$emit('input', this.otp.join(''))
        : '';
    },
    focusInput(input) {
      this.activeInput = Math.max(Math.min(this.inputCount - 1, input), 0);
    },
    focusNextInput() {
      this.focusInput(this.activeInput + 1);
    },
    focusPrevInput() {
      this.focusInput(this.activeInput - 1);
    },
    changeCodeAtFocus(value) {
      this.otp.splice(this.activeInput, 1, value);
      this.$emit('input', this.otp.join(''));
    },
    handleOnPaste(event) {
      event.preventDefault();

      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, this.inputCount - this.activeInput)
        .split('');

      if (this.inputType === 'number'
        && !pastedData.join('').match(/^\d+$/)) {
        return '';
      }

      const currentCharsInOtp = this.otp.slice(0, this.activeInput);
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData);

      this.otp = combinedWithPastedData.slice(0, this.inputCount);
      this.focusInput(combinedWithPastedData.slice(0, this.inputCount).length);

      return this.checkFilledAllInputs();
    },
    handleOnInput(e) {
      const val = e.target.value;

      if (val.trim().length > 1) {
        e.clipboardData = {
          getData: () => val.trim(),
        };
        this.handleOnPaste(e);

        return;
      }

      this.changeCodeAtFocus(val);
      this.focusNextInput();
    },
    clearInput() {
      this.otp = [];
      this.activeInput = 0;
    },
    handleOnKeyDown(event) {
      const currValue = this.otp[this.activeInput];

      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault();
          this.changeCodeAtFocus('');

          if (!currValue) {
            this.focusPrevInput();
          }
          break;
        case DELETE:
          event.preventDefault();
          this.changeCodeAtFocus('');
          break;
        case LEFT_ARROW:
          event.preventDefault();
          this.focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          this.focusNextInput();
          break;
        default:
          break;
      }
    },
  },
};
</script>
