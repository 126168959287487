<template>
  <div class="list-info">
    <div
      v-for="item in list"
      :key="item.id || item.label"
      class="list-info-element"
      :class="{
        'list-info-element--bold': item.bold,
        'list-info-element--warning': item.warning,
      }"
    >
      <div class="list-info-element__label">
        {{ item.label }}
      </div>
      <div
        v-if="item.additionalValue"
        class="list-info-element__value list-info-element__value--additional"
      >
        {{ item.additionalValue }}
      </div>
      <div
        class="list-info-element__value"
      >
        {{ item.value }}
      </div>
      <ui-button
        v-if="item.canBeRemoved"
        icon
        link
        class="list-info-element__delete-btn"
        @click.native="$emit('remove', item.id)"
      >
        <ui-icon
          name="trash"
          width="16"
          height="18"
        />
      </ui-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>
