<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="modal-alert"
    @closed="onClosed"
  >
    <template #title>
      <div
        class="modal-alert__title"
        :class="[
          `modal-alert__title--${titleSize}`
        ]"
      >
        {{ title }}
      </div>
    </template>

    <div class="modal-alert__body">
      <slot v-if="$scopedSlots.default" />
      <div v-else v-html="message"></div>
    </div>

    <template #footer="{ close }">
      <div class="w-100">
        <div class="modal-alert__actions">
          <div v-if="!noClose">
            <ui-button
              round
              outline
              class="modal-alert__btn"
              native-type="button"
              @click="cancel(close)"
            >
              {{ closeButton }}
            </ui-button>
          </div>
          <div>
            <ui-button
              round
              type="primary"
              native-type="button"
              class="modal-alert__btn"
              :disabled="applyDisabled"
              @click="apply(close)"
            >
              {{ applyButton }}
            </ui-button>
          </div>
        </div>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from './Modal.vue';

export default {

  components: {
    UiModal,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    title: String,
    message: String,
    applyDisabled: Boolean,
    applyButton: {
      type: String,
      default: 'Ok',
    },
    closeButton: {
      type: String,
      default: 'Cancel',
    },
    bodyOffset: {
      type: Boolean,
      default: true,
    },
    titleSize: {
      type: String,
      default: 'sm',
      validator: (value) => {
        return ['sm', 'lg'].includes(value);
      },
    },
    noClose: Boolean,
    noApplyClose: Boolean,
  },

  methods: {
    apply(close) {
      this.$emit('apply');

      if (!this.noApplyClose) {
        close({ performClose: true });
      }
    },
    cancel(close) {
      this.$emit('cancel');
      close({ performClose: true });
    },
    onClosed(payload) {
      if (!payload?.performClose) {
        this.$emit('cancel');
      }

      this.$emit('closed');
    },
  },
};
</script>
