<template>
  <input
    v-model="model"
    :type="inputType"
    :class="inputClasses"
    ref="input"
    min="0"
    max="9"
    maxlength="1"
    pattern="[0-9]"
    autocomplete="one-time-code"
    inputmode="numeric"
    required
    @input="handleOnInput"
    @keydown="handleOnKeyDown"
    @paste="handleOnPaste"
    @focus="handleOnFocus"
    @blur="handleOnBlur"
  />
</template>

<script>
export default {
  name: 'OtpInputItem',

  props: {
    value: {
      type: String,
      default: '',
    },
    inputType: String,
    inputClasses: String,
    focus: Boolean,
  },

  data() {
    return {
      model: '',
    };
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue;
      }
    },
    focus(newFocusValue, oldFocusValue) {
      if ((oldFocusValue !== newFocusValue) && this.focus) {
        this.$refs.input.focus();
        this.$refs.input.select();
      }
    },
  },

  methods: {
    isCodeNumeric(charCode) {
      return (charCode >= 48 && charCode <= 57)
        || (charCode >= 96 && charCode <= 105);
    },
    handleOnInput(event) {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1);
      }

      return this.$emit('input', event);
    },
    handleOnKeyDown(event) {
      const keyEvent = (event) || window.event;
      const charCode = keyEvent.which || keyEvent.keyCode;

      if (this.isCodeNumeric(charCode)
          || (charCode === 8)
          || (charCode === 37)
          || (charCode === 86)
          || (charCode === 39)
          || (charCode === 46)) {
        this.$emit('keydown', event);
      } else {
        keyEvent.preventDefault();
      }
    },
    handleOnPaste(event) {
      return this.$emit('paste', event);
    },
    handleOnFocus() {
      return this.$emit('focus');
    },
    handleOnBlur() {
      return this.$emit('blur');
    },
  },
};
</script>
