<template>
  <ui-input
    v-bind="$attrs"
    :type="type"
    class="ui-password"
    :addon-right-icon="['fa fa-lg', icon]"
    @click-right-icon="toggleType"
    v-on="$listeners"
    autocapitalize="off"
    spellcheck="false"
  ></ui-input>
</template>

<script>
export default {

  data() {
    return {
      type: 'password',
    };
  },

  computed: {
    icon() {
      return this.type === 'password' ? 'fa-eye-slash' : 'fa-eye';
    },
  },

  methods: {
    toggleType() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
  },
};
</script>
