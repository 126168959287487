<template>
  <div class="toggle">
    <button
      v-for="element in elements"
      :key="element.value"
      :class="{
        'active': selectedValue === element.value,
        'disabled': disabled,
      }"
      class="toggle__element"
      :disabled="disabled"
      @click.prevent="$emit('select', element.value)"
    >
      {{ element.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Toggle',

  model: {
    prop: 'selectedValue',
    event: 'select',
  },

  props: {
    elements: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: [String, Boolean],
      required: true,
    },
    disabled: Boolean,
  },
};
</script>
