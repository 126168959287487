<template>
  <el-upload
    v-bind="$attrs"
    drag
    class="ui-upload"
    ref="upload"
    action=""
    :accept="fileTypes"
    :on-change="select"
    :auto-upload="false"
    :show-file-list="false"
  >
    <div class="ui-upload__content el-upload__text">
      <ui-icon
        :size="32"
        class="ui-upload__logo"
        name="excel-lg"
      />
      <p class="ui-upload__title">
        Drop a file here
        <span>or</span>
      </p>
      <ui-button
        slot="trigger"
        type="primary"
        size="sm"
        class="ui-upload__button"
      >
        Select file
      </ui-button>
    </div>
  </el-upload>
</template>

<script>
import { Upload } from 'element-ui';
import UiButton from './Button.vue';
import UiIcon from './Icon.vue';

const defaultTypes = [
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export default {
  prop: {
    acceptedTypes: {
      type: Array,
      required: false,
    },
  },

  components: {
    [Upload.name]: Upload,
    UiButton,
    UiIcon,
  },

  computed: {
    fileTypes() {
      return this.acceptedTypes
        ? this.acceptedTypes.join(', ')
        : defaultTypes.join(', ');
    },
  },

  methods: {
    select(file, fileList) {
      if (fileList.length >= 1) {
        this.$refs.upload.clearFiles();
      }
      this.$emit('selected-file', file.raw);
    },
  },
};
</script>
