<template>
  <div class="swipe-pagination">
    <div
      v-for="(_, index) in pages"
      class="swipe-pagination__item"
      :class="{ 'swipe-pagination__item-active': index + 1 === page }"
      :key="index"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwipePagination',

  props: {
    page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
  },
};
</script>
