<template>
  <div
    class="ui-calendar"
    :class="{
      'ui-calendar_weekly': weekly,
      'ui-calendar_info': info,
    }"
  >
    <p
      v-if="info"
      class="ui-calendar_info__title"
    >
      {{ info }}
    </p>
    <div class="ui-calendar__header">
      <ui-icon-button
        name="angle-left"
        width="8px"
        height="14px"
        class="ui-calendar__prev"
        @click.stop="prev"
      />
      <div
        class="ui-calendar__title"
        @click.stop="selectYear"
      >
        {{ title }}
      </div>
      <ui-icon-button
        name="angle-right"
        width="8px"
        height="14px"
        class="ui-calendar__next"
        @click.stop="next"
      />
    </div>
    <div v-if="pickedMode === 'year'" class="ui-calendar__years">
      <div
        v-for="item in years"
        :key="item.value"
        class="ui-calendar__selection"
        :class="{
          'ui-calendar__selection_picked': isPicked(item.date),
          'ui-calendar__selection_disabled': isDisabled(item.date),
        }"
        @click.stop="pickYear(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <div v-else-if="pickedMode === 'month'" class="ui-calendar__months">
      <div
        v-for="item in months"
        :key="item.value"
        class="ui-calendar__selection"
        :class="{
          'ui-calendar__selection_picked': isPicked(item.date),
          'ui-calendar__selection_disabled': isDisabled(item.date),
        }"
        @click.stop="pickMonth(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <template v-else >
      <div class="ui-calendar__weekdays">
        <div
          v-for="wd in weekdays"
          :key="wd"
          class="ui-calendar__weekday"
        >
          {{ $t(`weekDays.${wd}`) }}
        </div>
      </div>
      <div
        v-for="(week, index) in calendar"
        :key="index"
        class="ui-calendar__week"
      >
        <div
          v-for="{ date, tooltip } in week"
          :key="date.toISODate()"
          class="ui-calendar__date"
          :class="[{
            'ui-calendar__date_hidden': !isMonthly(date),
            'ui-calendar__date_picked': isPicked(date),
            'ui-calendar__date_disabled': isDisabled(date),
          },
            classesDate({ date: date.toJSDate(), datetime: date }),
          ]"
          @click="pickDate(date)"
        >
          <ui-tooltip
            :disabled="!tooltip"
            placement="top"
          >
            <template #content>
              <span v-html="tooltip"></span>
            </template>
            <div class="ui-calendar__date-inner">
              {{ date.day }}
            </div>
          </ui-tooltip>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { Tooltip } from 'element-ui';
import { range } from 'lodash';

/**
 * Current local date.
 *
 * @var {DateTime}
 */
const local = DateTime.local();

export default {

  components: {
    UiTooltip: Tooltip,
  },

  props: {
    info: String,
    value: {
      type: Date,
    },
    month: {
      type: Number,
      default: local.month,
    },
    year: {
      type: Number,
      default: local.year,
    },
    startOfWeek: {
      type: Number,
      default: 1, // 1 or 7
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    classesDate: {
      type: Function,
      default: () => false,
    },
    tooltipDate: {
      type: Function,
      default: () => false,
    },
    max: Date,
    min: Date,
    weekly: Boolean,
    flexible: Boolean,
    mode: {
      type: String,
      default: 'day',
    },
  },

  data() {
    return {
      pickedDate: null,
      pickedMode: this.mode,
      pickedMonth: this.month,
      pickedYear: this.year,
    };
  },

  computed: {
    dateFrom() {
      return DateTime.fromObject({
        day: 1,
        month: this.pickedMonth,
        year: this.pickedYear,
      });
    },
    dateTo() {
      return this.dateFrom.endOf('month');
    },
    title() {
      if (this.pickedMode === 'year') {
        return '';
      }

      if (this.pickedMode === 'month') {
        return this.dateFrom.toFormat('yyyy');
      }

      return this.dateFrom.toFormat('LLLL yyyy');
    },
    years() {
      return range(this.pickedYear - 4, this.pickedYear + 5)
        .map((year) => {
          const date = DateTime.fromObject({ year });

          return {
            date,
            value: year,
            label: year,
          };
        });
    },
    months() {
      return range(1, 13).map((month) => {
        const date = DateTime.fromObject({ month });

        return {
          date,
          value: month,
          label: date.toFormat('LLL'),
        };
      });
    },
    calendar() {
      const start = this.getStartOfWeek(this.dateFrom);
      const end = this.getEndfWeek(this.dateTo);
      const result = [];
      const week = [];

      let date = start;

      do {
        if (week.length > 0 && date.weekday === this.startOfWeek) {
          result.push([...week]);
          week.splice(0);
        }

        week.push({
          date,
          tooltip: this.tooltipDate({
            datetime: date,
            date: date.toJSDate(),
          }),
        });

        date = date.plus({ days: 1 });
      } while (date <= end);

      return result;
    },
    weekdays() {
      return this.calendar[0].map(({ date }) => date.weekday - 1);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.pickedDate = null;

          return;
        }

        const datetime = DateTime.fromJSDate(value);

        if (!this.pickedDate || !this.isPicked(datetime) || !this.isMonthly(datetime)) {
          this.pickedDate = datetime;
          this.pickedMonth = datetime.month;
          this.pickedYear = datetime.year;
        }
      },
    },
    month: {
      immediate: true,
      handler(month) {
        this.pickedMonth = month;
      },
    },
    pickedMonth(pickedMonth) {
      if (pickedMonth !== this.month) {
        this.$emit('update:month', pickedMonth);
      }
    },
    year: {
      immediate: true,
      handler(year) {
        this.pickedYear = year;
      },
    },
    pickedYear(pickedYear) {
      if (pickedYear !== this.year) {
        this.$emit('update:year', pickedYear);
      }
    },
    mode: {
      immediate: true,
      handler(mode) {
        this.pickedMode = mode;
      },
    },
    pickedMode(pickedMode) {
      if (pickedMode !== this.mode) {
        this.$emit('update:mode', pickedMode);
      }
    },
  },

  methods: {
    pickYear({ date, value: year }) {
      if (this.isDisabled(date)) {
        return;
      }

      this.pickedYear = year;
      this.pickedMode = 'month';
    },
    pickMonth({ date, value: month }) {
      if (this.isDisabled(date)) {
        return;
      }

      this.pickedMonth = month;
      this.pickedMode = 'day';
    },
    pickDate(datetime) {
      if (this.weekly) {
        datetime = datetime.startOf('week');
      }

      if (this.isDisabled(datetime)) {
        return;
      }

      this.$emit('input', datetime.toJSDate());
    },
    prev() {
      if (this.pickedMonth === 1) {
        this.pickedMonth = 12;
        this.pickedYear -= 1;
      } else {
        this.pickedMonth -= 1;
      }
    },
    next() {
      if (this.pickedMonth === 12) {
        this.pickedMonth = 1;
        this.pickedYear += 1;
      } else {
        this.pickedMonth += 1;
      }
    },
    selectYear() {
      if (!this.flexible) {
        return;
      }

      this.pickedMode = 'year';
    },
    getStartOfWeek(datetime) {
      if (datetime.weekday >= this.startOfWeek) {
        return datetime.minus({ days: datetime.weekday - this.startOfWeek });
      }

      return datetime.minus({ days: 7 - this.startOfWeek + datetime.weekday });
    },
    getEndfWeek(datetime) {
      return this.getStartOfWeek(datetime).plus({ days: 7 });
    },
    isMonthly(datetime) {
      return datetime.hasSame(this.dateFrom, 'month');
    },
    getUnit() {
      if (this.pickedMode === 'year') {
        return 'year';
      }

      if (this.pickedMode === 'month') {
        return 'month';
      }

      if (this.weekly) {
        return 'week';
      }

      return 'day';
    },
    isPicked(datetime) {
      if (!this.pickedDate) {
        return false;
      }

      return datetime.hasSame(this.pickedDate, this.getUnit());
    },
    isDisabled(datetime) {
      const unit = this.getUnit();

      if (this.min && datetime.startOf(unit) < DateTime.fromJSDate(this.min)) {
        return true;
      }

      if (this.max && datetime.endOf(unit) > DateTime.fromJSDate(this.max).endOf('day')) {
        return true;
      }

      return this.disabledDate({ datetime, date: datetime.toJSDate() });
    },
  },
};
</script>
