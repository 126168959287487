<template>
  <div class="alert" :class="{ [`alert-${type}`]: type }">
    <div class="d-flex align-items-center">
      <ui-icon
        v-if="icon"
        :name="icon"
        size="32px"
        class="mr-4"
      />
      <div class="flex-fill">
        <strong v-if="title">{{ title }}</strong>
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import UiIcon from './Icon.vue';

export default {

  components: {
    UiIcon,
  },

  props: {
    title: String,
    message: String,
    icon: String,
    type: String,
  },
};
</script>
