<template>
  <div>
    <slot name="activator" v-bind="scope" />

    <SlideYUpTransition :duration="animationDuration">
      <div
        class="modal fade"
        @click.self="closable && close()"
        :class="{
          'show d-block': opened,
          'd-none': !opened,
          'modal-mini': type === 'mini',
          'opaque': opaque,
        }"
        v-show="opened"
        tabindex="-1"
        role="dialog"
        :aria-hidden="!opened"
      >
        <div
          class="modal-dialog modal-dialog-scrollable"
          :class="[{
            'modal-notice': type === 'notice',
            [`modal-${size}`]: size,
            'modal-wide': wide,
          }, modalClasses]"
        >
          <div class="modal-content">
            <div v-if="isLoading" class="modal-body my-5 py-5">
              <ui-loading />
            </div>

            <div
              v-if="!noHeader && !isLoading"
              class="modal-header"
              :class="headerClasses"
            >
              <slot name="close-button" v-bind="scope">
                <button
                  v-if="closable"
                  type="button"
                  @click="close"
                  class="close"
                  data-dismiss="modal"
                  :aria-hidden="!opened"
                >
                  <ui-icon name="close" size="20px" />
                </button>
              </slot>
              <slot name="header" v-bind="scope">
                <div class="modal-title">
                  <slot name="title" v-bind="scope" />
                </div>
              </slot>
            </div>

            <div
              v-show="!isLoading"
              ref="modal-body"
              class="modal-body"
              :class="[bodyClasses, { 'modal-body__scrollable': scrollable }]"
            >
              <slot v-bind="scope" />
            </div>

            <div
              v-if="!noFooter"
              v-show="!isLoading"
              class="modal-footer"
              :class="footerClasses"
            >
              <slot name="footer" v-bind="scope" />
            </div>
          </div>
        </div>
      </div>
    </SlideYUpTransition>
  </div>
</template>

<script>
import { SlideYUpTransition } from 'vue2-transitions';
import UiLoading from './Loading.vue';

export default {

  components: {
    SlideYUpTransition,
    UiLoading,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    show: Boolean,
    opaque: Boolean,
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    size: String,
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
    isLoading: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    wide: Boolean,
  },

  data() {
    return {
      opened: false,
      modalBody: null,
    };
  },

  computed: {
    scope() {
      const { open, close } = this;

      return {
        open,
        close,
      };
    },
  },

  mounted() {
    this.modalBody = document.querySelector('.modal-body__scrollable');

    if (this.modalBody) {
      this.modalBody.addEventListener('scroll', (e) => {
        const element = e.target;

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          this.$emit('scrolled-bottom');
        }
      });
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show === this.opened) {
          return;
        }

        if (show) {
          this.open();
        } else {
          this.close();
        }
      },
    },
    opened(opened) {
      if (opened !== this.show) {
        this.$emit('input', opened);
      }
    },
  },

  beforeDestroy() {
    this.toggleClass(false);

    if (this.modalBody) {
      this.modalBody.removeEventListener('scroll', () => this.$emit('scroll'));
    }
  },

  methods: {
    open(payload) {
      this.$emit('before-open', payload);
      this.opened = true;
      this.toggleClass(true);
      this.$emit('opened', payload);
    },
    close(payload) {
      this.$emit('before-close', payload);
      this.opened = false;
      this.toggleClass(false);
      this.$emit('closed', payload);
    },
    toggleClass(show) {
      const documentClasses = document.body.classList;

      if (show) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
};
</script>

<style>
  .modal.show {
    background-color: rgba(0, 0, 0, 0.3)
  }
  .modal.show.opaque {
    background-color: #eaeaea;
  }
</style>
