<template>
  <div class="ui-date-period">
    <ui-select
      v-model="yearPicked"
      :items="yearList"
      label-key="label"
      value-key="value"
      placeholder="Select year"
      class="ui-date-period__year"
      bordered
    />
    <ui-select
      v-model="periodPicked"
      :items="periodList"
      label-key="label"
      value-key="value"
      placeholder="Select period"
      class="ui-date-period__period"
      bordered
      @input="periodPick"
    />
  </div>
</template>

<script>
import { uniq } from 'lodash';
import parseDate from '@/utils/parseDate';
import formatPeriod from '@/utils/formatPeriod';
import UiSelect from './Inputs/Select.vue';

export default {

  components: {
    UiSelect,
  },

  props: {
    value: null,
    valueKey: String,
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    yearPicked: null,
    periodPicked: null,
  }),

  computed: {
    itemPicked() {
      if (this.periodPicked) {
        return this.items.find((item) => {
          return this.getPeriodLabel(item) === this.periodPicked;
        });
      }

      return null;
    },
    yearList() {
      return uniq(
        this.items.map((item) => this.getPeriodYear(item)),
      ).map((value) => ({
        label: value,
        value,
      }));
    },
    periodList() {
      return this.items
        .filter((item) => this.yearPicked === this.getPeriodYear(item))
        .map((item) => this.getPeriodLabel(item))
        .map((value) => ({
          label: value,
          value,
        }));
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          const periodSelected = this.valueKey
            ? this.items.find((item) => item[this.valueKey] === value)
            : value;

          if (periodSelected) {
            this.yearPicked = this.getPeriodYear(periodSelected);
            this.periodPicked = this.getPeriodLabel(periodSelected);

            return;
          }
        }

        this.yearPicked = null;
        this.periodPicked = null;
      },
    },
    yearPicked(yearPicked) {
      if (!this.itemPicked) {
        return;
      }

      if (yearPicked === this.getPeriodYear(this.itemPicked)) {
        return;
      }

      this.periodPicked = null;
    },
  },

  methods: {
    getPeriodLabel({ startDate, endDate }) {
      return formatPeriod(startDate, endDate);
    },
    getPeriodYear({ startDate }) {
      return parseDate(startDate, false /* default date */)?.getFullYear();
    },
    periodPick() {
      const value = this.valueKey
        ? this.itemPicked?.[this.valueKey]
        : this.itemPicked;

      if (value !== this.value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>
