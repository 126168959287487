<template>
  <span class="ui-loading" :class="{ 'ui-loading__inline': inline }">
    <span class="spinner-border spinner-border-sm"></span>
  </span>
</template>

<script>
export default {

  props: {
    inline: Boolean,
  },
};
</script>
