<template>
  <div class="ui-emoji">
    <div
      v-for="category in categoriesList"
      :key="category"
      class="ui-emoji__body"
    >
      <span class="ui-emoji__title">{{ parseCategory(category) }}</span>
      <div class="ui-emoji__category">
        <span
          v-for="emoji in emojisList[category]"
          :key="emoji.name"
          class="ui-emoji__item"
          @click="handleClick(emoji.hex)"
        >
          {{ parseEmoji(emoji.hex) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { categories, emojis } from '@/config/emojis';

export default {
  name: 'Emoji',

  computed: {
    emojisList() {
      return emojis;
    },
    categoriesList() {
      return categories;
    },
  },

  methods: {
    parseEmoji(hex) {
      return String.fromCodePoint(hex);
    },
    parseCategory(title) {
      return title.split('_').join(' ').toUpperCase();
    },
    handleClick(hex) {
      this.$emit('selected', this.parseEmoji(hex));
    },
  },
};
</script>
