<template>
  <div
    class="form-check"
    :class="{
      disabled: disabled,
      'form-check_small': small,
      'form-check_white': white,
      'form-check-inline': inline,
    }">
    <label :for="cbId" class="form-check-label">
      <input
        v-bind="$attrs"
        :id="cbId"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        :true-value="trueValue"
        :false-value="falseValue"
        v-model="model"
        :value="value"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>

<script>
export default {

  model: {
    prop: 'checked',
  },

  props: {
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    value: {
      type: String,
    },
    checked: {
      type: [Array, Boolean, String],
      description: 'Whether checkbox is checked',
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline',
    },
    white: Boolean,
    small: Boolean,
  },

  data() {
    return {
      cbId: '',
      touched: false,
    };
  },

  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      },
    },
  },

  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>
