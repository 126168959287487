<template>
  <ui-input
    tag="vue-tel-input"
    class="form-group-phone"
    :key="`phone-${phoneIso}`"
    :label="phoneLoading ? '' : label"
    :error="error"
    :required="required"
    :is-loading="phoneLoading"
    :is-filled="inputValue && !!inputValue.length"

    v-bind="$attrs"
    placeholder=""
    :value="inputValue"
    :all-countries="phoneCountries"
    :default-country="phoneIso"
    autocomplete="off"
    enabled-country-code
    disabled-fetching-country
    :dropdown-options="{ disabledDialCode: true }"
    :input-options="{ showDialCode: false }"
    v-mask="phoneMask"
    :disabled="disabled"
    @country-changed="countryChanged"
    :custom-validate="/.*/"
    @input="onInput"
  />
</template>

<script>
import findCountry from '@/utils/findCountry';
import formatPhone from '@/utils/formatPhone';
import { allCountries as countries } from '@/config/countries';
import placeholders from './placeholders';
import calling from './calling';
import UiInput from '../Inputs/Input.vue';

/**
 * Find dial code from stripe by country iso code.
 *
 * @param  {string} iso
 * @return {string}
 */
function findDialCode(iso) {
  return Object.keys(calling).find((code) => calling[code].includes(iso));
}

const phoneCountries = countries.reduce((carry, { iso, ...other }, index) => {
  iso = iso.toUpperCase();

  if (iso in placeholders) {
    carry[index] = {
      ...other,
      iso2: iso,
      dialCode: findDialCode(iso),
    };
  }

  return carry;
}, []);

/**
 * Find iso code by phone.
 *
 * @param  {string} phone
 * @return {string}
 */
function findIso(phone) {
  phone = formatPhone(phone);

  return phoneCountries.find(({ iso2, dialCode, areaCodes }) => {
    return areaCodes
      ? areaCodes.some((areaCode) => phone.startsWith(String(dialCode) + String(areaCode)))
      : phone.startsWith(dialCode);
  })?.iso2;
}

export default {

  components: {
    UiInput,
  },

  props: {
    value: {
      required: true,
    },
    label: String,
    error: String,
    placeholder: String,
    disabled: Boolean,
    required: Boolean,
    isLoading: Boolean,
    countryCode: String,
  },

  data: () => ({
    parsing: true,
    typing: false,
    inputValue: '',
    phoneIso: '',
    phoneCountries,
  }),

  computed: {
    phoneLoading() {
      return this.isLoading || this.parsing;
    },
    phonePlaceholders() {
      const list = placeholders?.[this.phoneIso] || '';

      return Array.isArray(list) ? list : [list];
    },
    phonePrefix() {
      return phoneCountries.find(({ iso2 }) => iso2 === this.phoneIso)?.dialCode;
    },
    phoneMask() {
      const masks = this.phonePlaceholders.map((value) => {
        return value.replace(/\d/g, '#');
      });

      return {
        mask: masks,
        masked: true,
        tokens: {
          z: { pattern: /[1-9]/ },
          '#': { pattern: /\d/ },
        },
      };
    },
    phoneFull() {
      if (!this.phonePrefix || !this.inputValue) {
        return this.inputValue || '';
      }

      return `+${this.phonePrefix}${formatPhone(this.inputValue)}`;
    },
    invalid() {
      return this.phoneMask.mask.every((mask) => {
        return mask.length !== `${this.inputValue}`.length;
      });
    },
  },

  watch: {
    value: {
      immediate: true,
      async handler(value) {
        if (this.isLoading) {
          return;
        }

        if (value) {
          let phoneIso = findIso(value);

          if (!phoneIso) {
            phoneIso = await findCountry();
          }

          if (formatPhone(value) !== formatPhone(this.phoneFull)) {
            this.phoneIso = phoneIso;
            this.inputValue = `${value}`.replace(new RegExp(`^\\+?${this.phonePrefix}`, 'g'), '');
          }
        } else {
          this.inputValue = '';

          if (!this.phoneIso) {
            this.phoneIso = this.countryCode ? this.countryCode : await findCountry();
          }
        }

        this.parsing = false;
      },
    },
  },

  methods: {
    countryChanged({ iso2 }) {
      this.phoneIso = iso2;
    },
    onInput($event) {
      this.$emit('typing');
      this.change($event);
    },
    change(value) {
      this.inputValue = value;
      this.$emit('input', this.phoneFull);
      this.$emit('validate', this.invalid);
      this.$emit('change', {
        value: this.phoneFull,
        input: this.inputValue,
        invalid: this.invalid,
      });
    },
  },
};
</script>

<style lang="scss">
.vue-tel-input {
  &.disabled {
    opacity: initial;
  }
  .vti__input {
    opacity: initial;
  }
  .vti__flag {
    background-size: 5630px 15px !important;
    background-image: url(./flags.png) !important;
    height: 15px !important;

    strong {
      font-weight: normal;
    }
  }
  .vti__dropdown {
    outline: 0 !important;
    background-color: #fff !important;
  }
}
</style>
