<template>
  <ui-modal v-bind="$attrs" v-on="$listeners" :closable="closable">
    <template #header>
      <span class="modal-success__title">
        {{ title || autoTitle }}
      </span>
    </template>
    <p v-html="message" class="modal-success__body py-2" />
    <template #footer="{ close }">
      <div class="d-flex col-12 flex-column justify-content-center align-items-center">
        <div class="modal-success__icon">
          <ui-icon
            :name="customIcon"
            :size="customIconSize"
          />
        </div>

        <ui-button
          v-if="!noButton"
          round
          type="primary"
          native-type="button"
          class="modal-success__button col-6 w-100 h-100"
          @click="close()"
        >
          {{ closeButton }}
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from './Modal.vue';

const MODAL_STATUS_TYPES = {
  success: {
    title: 'successModal.title',
    icon: 'circle-check',
    iconSize: '96',
  },
  warning: {
    title: 'errorModal.title',
    icon: 'warning',
    iconSize: '100',
  },
};

export default {

  components: {
    UiModal,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    message: String,
    type: {
      type: String,
      default: 'success',
      validator: (value) => Object.keys(MODAL_STATUS_TYPES).includes(value),
    },
    icon: String,
    iconSize: String,
    closable: {
      type: Boolean,
      default: true,
    },
    closeButton: {
      type: String,
      default: 'Ok',
    },
    noButton: Boolean,
  },
  computed: {
    statusType() {
      return MODAL_STATUS_TYPES[this.type];
    },
    autoTitle() {
      return this.$t(this.statusType.title);
    },
    customIcon() {
      return this.icon || this.statusType.icon;
    },
    customIconSize() {
      return this.iconSize || this.statusType.iconSize;
    },
  },
};
</script>
