<template>
  <button type="button" class="navbar-toggler" aria-label="Navbar toggle button">
    <ui-icon
      name="bar"
      size="30px"
    />
  </button>
</template>

<script>
import UiIcon from '../Icon.vue';

export default {

  name: 'navbar-toggler',

  components: {
    UiIcon,
  },
};
</script>
