<template>
  <ui-dropdown
    v-if="items.length > 0"
    v-bind="$attrs"
    v-on="$listeners"
    direction="bottom"
    position="left"
    :disabled="disabled"
    append-to-body
  >
    <template #control>
      <ui-icon-button
        name="ellipsis-h"
        width="16px"
        height="4px"
        :disabled="disabled"
      />
    </template>
    <li
      v-for="item in items"
      :key="item[valueKey]"
      class="dropdown-item"
      :class="{
        'dropdown-item--right-align': rightAlign,
        'dropdown-item--disabled': item.disabled,
      }"
      @click="onClick($event, item)"
    >
      <slot :name="item.id" v-bind="item">
        <span class="dropdown-item__label">
          {{ item.label }}
        </span>
        <ui-icon
          v-if="item.icon"
          :name="item.icon"
          size="24px"
          class="dropdown-item__icon"
        />
      </slot>
    </li>
  </ui-dropdown>
</template>

<script>
import UiDropdown from './Dropdown.vue';

export default {
  name: 'ActionDown',

  components: {
    UiDropdown,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    valueOriginal: Boolean,
    disabled: Boolean,
    rightAlign: Boolean,
  },

  methods: {
    onClick(event, item) {
      if (item.disabled) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }

      this.$emit('select', this.valueOriginal ? item : item[this.valueKey]);
    },
  },
};
</script>
