<template>
  <div class="ui-calendar-period">
    <div class="ui-calendar-period__fixed">
      <ui-select
        v-model="period"
        :items="periodPresets"
        @change="selectPeriod"
        bordered
      />
    </div>
    <ui-input
      class="ui-calendar-period__custom is-filled"
      :input-icon="prefixIcon"
      bordered
    >
      <ui-date-picker
        v-model="calendar"
        :prefix-icon="prefixIcon"
        type="daterange"
        :range-separator="rangeSeparator"
        :start-placeholder="startPlaceholder"
        :end-placeholder="endPlaceholder"
        :format="dateFormat"
        :editable="false"
        :max="dateMax"
        @change="selectDate"
      />
    </ui-input>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import UiDatePicker from './DatePicker.vue';
import UiSelect from './Inputs/Select.vue';
import UiInput from './Inputs/Input.vue';

export default {

  components: {
    UiDatePicker,
    UiSelect,
    UiInput,
  },

  props: {
    value: Array,
    prefixIcon: {
      type: String,
      default: 'calendar',
    },
    rangeSeparator: {
      type: String,
      default: '—',
    },
    startPlaceholder: {
      type: String,
      default: 'From',
    },
    endPlaceholder: {
      type: String,
      default: 'To',
    },
    dateFormat: {
      type: String,
      default: 'dd MMM yyyy',
    },
    dateMax: {
      type: Date,
      default: () => new Date(),
    },
  },

  data() {
    return {
      period: '30DAYS',
      calendar: null,
    };
  },

  computed: {
    periodPresets() {
      return [
        {
          value: 'CUSTOM',
          name: 'Custom',
        },
        {
          value: '7DAYS',
          name: this.$t('transactionGraph.datePresets.7DAYS'),
        },
        {
          value: '30DAYS',
          name: this.$t('transactionGraph.datePresets.30DAYS'),
        },
        {
          value: '3MONTHS',
          name: this.$t('transactionGraph.datePresets.3MONTHS'),
        },
        {
          value: '1YEAR',
          name: this.$t('transactionGraph.datePresets.1YEAR'),
        },
      ];
    },
  },

  watch: {
    value: {
      handler(val) {
        if (val && val.length > 1) {
          this.period = this.getPeriodFromDate(val);
          this.calendar = val;
        }
      },
    },
  },

  mounted() {
    const datePeriod = this.getDateFromPeriod(this.period);
    this.calendar = datePeriod;
  },

  methods: {
    isDateEqual(a, b) {
      return a.hasSame(b, 'day');
    },
    getPeriodFromDate(value) {
      const from = DateTime.fromJSDate(value[0]);
      const to = DateTime.now();
      let period = '';

      if (this.isDateEqual(from, to.minus({ days: 7 }))) {
        period = '7DAYS';
      } else if (this.isDateEqual(from, to.minus({ days: 30 }))) {
        period = '30DAYS';
      } else if (this.isDateEqual(from, to.minus({ months: 3 }))) {
        period = '3MONTHS';
      } else if (this.isDateEqual(from, to.minus({ years: 1 }))) {
        period = '1YEAR';
      } else {
        period = 'CUSTOM';
      }

      return period;
    },
    getDateFromPeriod(value) {
      let dateTo = DateTime.local();
      let dateFrom = dateTo.minus({ months: 1 });

      switch (value) {
        case '7DAYS':
          dateFrom = dateTo.minus({ days: 7 });
          break;
        case '30DAYS':
          dateFrom = dateTo.minus({ days: 30 });
          break;
        case '3MONTHS':
          dateFrom = dateTo.minus({ months: 3 });
          break;
        case '1YEAR':
          dateFrom = dateTo.minus({ years: 1 });
          break;
        default:
          return;
      }

      dateFrom = dateFrom.toJSDate();
      dateTo = dateTo.toJSDate();

      /* eslint-disable consistent-return */
      return [dateFrom, dateTo];
    },
    selectPeriod(value) {
      if (value !== 'CUSTOM') {
        const period = this.getDateFromPeriod(value);
        this.calendar = period;
        this.period = this.getPeriodFromDate(value);
        this.$emit('input', period);
      }
    },
    selectDate(value) {
      this.period = this.getPeriodFromDate(value);
      this.$emit('input', value);
    },
  },
};
</script>
