<template>
  <ui-card v-bind="$attrs" class="card-stats">
    <template #header>
      <h4 class="card-title d-flex align-items-center justify-content-between">
        {{ title }}
      </h4>
    </template>

    <template #default>
      <slot>
        <div class="d-flex">
          <div v-if="icon" class="text-nowrap">
            <ui-icon :name="icon" size="44px" />
          </div>
          <div class="card-stats__value-wrapper">
            <div class="card-stats__value text-nowrap">
              <div>{{ value }}</div>
            </div>
            <div v-if="$slots.link" class="card-stats__value-link">
              <slot name="link"></slot>
            </div>
          </div>
        </div>
      </slot>
    </template>
  </ui-card>
</template>

<script>
import UiIcon from '../Icon.vue';

export default {

  components: {
    UiIcon,
  },

  props: {
    value: null,
    title: {
      type: [String, Number],
      description: 'Card title',
    },
    icon: {
      type: String,
      description: 'Card icon',
    },
  },
};
</script>

<style lang="scss">
.card-stats {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 13px;

  &__value {
    margin-left: 20px;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: black;
    display: flex;
    &-link {
      margin-left: 20px;
      margin-top: 6px;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
