<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    :type="type"
    :disabled="disabled"
    class="icon-button"
    :class="{
      'opacity-60': disabled,
      [`bg-${bgColor}`]: bgColor,
    }"
  >
    <ui-icon
      :name="name"
      :size="size"
      :width="width"
      :height="height"
    />
  </button>
</template>

<script>
import UiIcon from './Icon.vue';

export default {

  components: {
    UiIcon,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'button',
    },
    bgColor: String,
    size: [Number, String],
    width: [Number, String],
    height: [Number, String],
    disabled: Boolean,
  },
};
</script>
